<template>
  <div>
    <div class="pay-view" v-if="showAnimate">
      <div class="animate-wrap">
        <div class="Lazy-img bg" alt="">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/write_bg.png"
              class="van-image__img"
            />
          </div>
        </div>
        <div class="banner">
          <div class="content">
            <div class="title">正在择选吉日…</div>
            <div id="info" style="display: none">
              <div class="mingpang">
                <p class="delay500">姓名: {{ infos[0].username1 }}</p>
                <p class="delay1000">
                  出生时间: {{ infos[0].usergBirthTime1 }}
                </p>
                <!-- <p class="delay1500">
                  姓名: {{ infos[1].username2 || '-' }}
                </p>
                <p class="delay2000">
                  出生时间: {{ infos[1].usergBirthTime2 || '-' }}
                </p> -->
              </div>
            </div>
            <div>
              <div id="bazi1" class="bazi" style="display: none">
                <div>
                  <p class="delay1000"><span>根</span>壬午</p>
                </div>
                <div>
                  <p class="delay2000"><span>苗</span>戌子</p>
                </div>
                <div>
                  <p class="delay5000"><span>花</span>甲申</p>
                </div>
                <div>
                  <p class="delay7500"><span>果</span>辛丑</p>
                </div>
              </div>
              <div id="bazi2" class="bazi" style="display: none">
                <div>
                  <p class="delay500"><span>壬</span>伤官</p>
                </div>
                <div>
                  <p class="delay1000"><span>戌</span>正宫</p>
                </div>
                <div>
                  <p class="delay1500"><span>甲</span>日元</p>
                </div>
                <div>
                  <p class="delay2000"><span>辛</span>七杀</p>
                </div>
              </div>
              <div id="bazi3" class="bazi" style="display: none">
                <div>
                  <p class="delay500"><span>午</span>食神</p>
                </div>
                <div>
                  <p class="delay1000"><span>子</span>七杀</p>
                </div>
                <div>
                  <p class="delay1500"><span>申</span>比肩</p>
                </div>
                <div>
                  <p class="delay2000"><span>丑</span>伤官</p>
                </div>
              </div>
              <div id="bazi4" class="bazi" style="display: none">
                <div>
                  <p class="delay500"><span>壬</span>伤官</p>
                </div>
                <div>
                  <p class="delay1000"><span>戌</span>正宫</p>
                </div>
                <div>
                  <p class="delay1500"><span>甲</span>日元</p>
                </div>
                <div>
                  <p class="delay2000"><span>辛</span>七杀</p>
                </div>
              </div>
            </div>
            <div>
              <div id="bazi5" class="bazi" style="display: none">
                <div>
                  <p class="delay500"><span>火</span>旺</p>
                </div>
                <div>
                  <p class="delay1000"><span>土</span>相</p>
                </div>
                <div>
                  <p class="delay1500"><span>木</span>休</p>
                </div>
                <div>
                  <p class="delay2000"><span>水</span>囚</p>
                </div>
                <div>
                  <p class="delay2500"><span>金</span>死</p>
                </div>
              </div>
              <div id="bazi6" style="display: none">
                <div class="mingpang">
                  <p class="delay1000">
                    大运: <span>你将在（8年起大运）</span>
                  </p>
                  <p class="delay2000">流年: <span>壬寅年2022年</span></p>
                </div>
              </div>
              <div id="bazi7" style="display: none">
                <div class="mingpang">
                  <p class="delay1000">喜用神: <span>土</span></p>
                  <p class="delay2000">欠缺五行：<span>五行木弱</span></p>
                </div>
              </div>
            </div>
            <div id="bazi8" style="display: none">
              <div class="mingpang">
                <p class="delay500">欠缺五行: <span>五行缺金</span></p>
                <p class="delay1000"><span>2月或5月有危机</span></p>
                <!-- <p class="delay1500">命中格局: <span>一生或有大权</span></p>
                <p class="delay2000">你将在 <span>8年3月</span>后起大运</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="progress-wrap">
          <div class="nav">
            <p id="bazi" class="active">八字分析<span class="line"></span></p>
            <p id="jiri" class="">吉日筛选<span class="line"></span></p>
            <p id="mingli" class="">命理匹配<span class="line"></span></p>
          </div>
          <div id="mingpan-1" class="mingpang-wrap">
            <div class="item">
              <p>正在录入命主信息</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
            <div class="item">
              <p>正在生成八字</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
          </div>
          <div id="mingpan-2" class="mingpang-wrap">
            <div class="item">
              <p>正在挑选{{ luckyName.replace("吉日", "") }}吉日</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
            <div class="item">
              <p>正在挑选{{ luckyName.replace("吉日", "") }}吉时</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
            <div class="item">
              <p>正在挑选{{ luckyName.replace("吉日", "") }}黄道吉日</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
          </div>
          <div id="mingpan-3" class="mingpang-wrap">
            <div class="item">
              <p>正在计算八字冲突</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
            <div class="item">
              <p>正在计算生肖冲煞</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
            <div class="item">
              <p>正在计算五行旺弱</p>
              <div
                class="van-progress"
                style="background: rgb(225, 193, 149); height: 6px"
              >
                <span
                  class="van-progress__portion"
                  style="background: rgb(174, 50, 48); width: 0"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-view" v-if="!showAnimate">
      <div class="report-wrap">
        <div class="Lazy-img tit">
          <div class="van-image">
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 0"
              src="https://qiniu.zhiyunys.com/zejiri/banner_0.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 1"
              src="https://qiniu.zhiyunys.com/zejiri/banner_1.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 2"
              src="https://qiniu.zhiyunys.com/zejiri/banner_2.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 3"
              src="https://qiniu.zhiyunys.com/zejiri/banner_3.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 4"
              src="https://qiniu.zhiyunys.com/zejiri/banner_4.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 5"
              src="https://qiniu.zhiyunys.com/zejiri/banner_5.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 6"
              src="https://qiniu.zhiyunys.com/zejiri/banner_6.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 7"
              src="https://qiniu.zhiyunys.com/zejiri/banner_7.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 8"
              src="https://qiniu.zhiyunys.com/zejiri/banner_8.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 9"
              src="https://qiniu.zhiyunys.com/zejiri/banner_9.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 10"
              src="https://qiniu.zhiyunys.com/zejiri/banner_10.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 11"
              src="https://qiniu.zhiyunys.com/zejiri/banner_11.png"
              class="van-image__img"
            />
          </div>
        </div>
        <div class="des">
          已为 <span>286340</span> 位用户服务，帮助他们择选了
          <span>{{ luckyName || "吉日" }}</span> ，
          <span>98.98%</span>的用户给予了好评
        </div>
        <div class="pay-wrap">
          <div class="Lazy-img bg">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/payprice-bg.png"
                class="van-image__img"
              />
            </div>
          </div>
          <div class="title">
            已为你<span v-if="infos[1].username2">们</span>择得与八字相合的吉日
          </div>
          <p v-if="infos[1].username2" class="gettime">
            <b>{{ luckyName.replace("吉日", "") || "吉日" }}时间：</b>三年内
          </p>
          <div class="otherinfo-wrap" v-if="!infos[1].username2">
            <div class="wrap">
              <p><span>缘主：</span>{{ infos[0].username1 }}</p>
              <!-- <p><span>八字：</span>庚午 壬午 辛亥 戊子</p> -->
            </div>
            <div class="wrap">
              <p><span>公(阳)历：</span> {{ infos[0].usergBirthTime1 }}</p>
            </div>
            <div class="wrap">
              <p><span>农(阴)历：</span> {{ infos[0].usernBirthTime1 }}</p>
            </div>
            <div class="wrap">
              <p><span>择日范围：</span>三年内</p>
            </div>
          </div>
          <div v-else class="info-wrap">
            <div class="item">
              <div class="head-wrap">
                <div class="Lazy-img head">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/pay_unkown.png"
                      class="van-image__img"
                    />
                  </div>
                </div>
                <!-- <div class="Lazy-img label">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/pay_label1.png"
                      class="van-image__img"
                    />
                  </div>
                </div> -->
              </div>
              <p class="name">{{ infos[0].username1 }}</p>
              <p>{{ infos[0].usergBirthTime1 }}</p>
              <p>{{ infos[0].usernBirthTime1 }}</p>
              <!-- <p class="bazi-tips">八字：庚午 壬午 辛亥 戊子</p> -->
              <!---->
            </div>
            <p class="love"><!----></p>
            <div class="item">
              <div class="head-wrap">
                <div class="Lazy-img head">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/pay_unkown.png"
                      class="van-image__img"
                    />
                  </div>
                </div>
                <!-- <div class="Lazy-img label">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/pay_label2.png"
                      class="van-image__img"
                    />
                  </div>
                </div> -->
              </div>
              <p class="name">{{ infos[1].username2 }}</p>
              <p>{{ infos[1].usergBirthTime2 }}</p>
              <p>{{ infos[1].usernBirthTime2 }}</p>
              <!-- <p class="bazi-tips">八字：庚午 壬午 辛亥 戊子</p> -->
            </div>
          </div>
          <div class="wrapper">
            <div class="price">
              结缘价：<span>¥</span><b>{{ price }}</b
              ><span class="old">原价¥168</span>
            </div>
            <div class="des">
              距优惠结束还剩 <span>{{ hour }}</span
              ><b>:</b><span>{{ minute }}</span
              ><b>:</b><span>{{ second }}</span>
            </div>
            <div class="paybtn" @click="alipay()">
              <div class="Lazy-img identification">
                <div class="van-image">
                  <img
                    src="https://qiniu.zhiyunys.com/zejiri/pay_rm.png"
                    class="van-image__img"
                  />
                </div>
              </div>
              <div class="Lazy-img paybtns">
                <div class="van-image">
                  <img
                    src="https://qiniu.zhiyunys.com/zejiri/alipay.png"
                    class="van-image__img"
                  />
                </div>
              </div>
              支付宝支付
            </div>
            <div class="paybtn weixin" @click="wxpay()">
              <div class="Lazy-img paybtns">
                <div class="van-image">
                  <img
                    src="https://qiniu.zhiyunys.com/zejiri/weixin.png"
                    class="van-image__img"
                  />
                </div>
              </div>
              微信支付
            </div>
          </div>
        </div>
      </div>
      <div class="get-list">
        <div class="Lazy-img title">
          <div class="van-image">
            <img
              class="van-image__img"
              src="https://qiniu.zhiyunys.com/zejiri/pay_subtitle.png"
              lazy="loaded"
            />
          </div>
        </div>
        <div>
          <div class="item" @click="alipay()">
            <div class="Lazy-img">
              <div class="van-image">
                <img
                  class="van-image__img"
                  src="https://qiniu.zhiyunys.com/zejiri/pay_details1.png"
                  lazy="loaded"
                />
              </div>
            </div>
            <span class="tip">
              所选择日范围内的 <b>所有适合你出行</b> 的吉日
            </span>
          </div>
          <div class="item">
            <div class="Lazy-img">
              <div class="van-image">
                <img
                  class="van-image__img"
                  src="https://qiniu.zhiyunys.com/zejiri/pay_details2_bg.png"
                  lazy="loading"
                />
              </div>
            </div>
            <div class="comments-wrap">
              <ul class="comments">
                <li>
                  谭艳屏 51分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  小珍子 46分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  黄吟兰 42分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  龙亮臻 34分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  静子 19分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
                <li>
                  郭人豪 14分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  伟明 12分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
                <li>
                  金霏 7分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
                <li>
                  谭艳屏 51分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  小珍子 46分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  黄吟兰 42分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  龙亮臻 34分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  静子 19分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
                <li>
                  郭人豪 14分钟前 购买了<b>出行吉日￥58</b>
                  <span>9.9分好评</span>
                </li>
                <li>
                  伟明 12分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
                <li>
                  金霏 7分钟前 购买了<b>出行吉日￥58</b> <span>9.9分好评</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed-menu">
        <div class="query-order" @click="jumpToOrder">
          <div class="Lazy-img" alt="">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/query-order.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <!-- <div class="complaint" @click="jumpToHelp">
        <div class="Lazy-img" alt="">
          <div class="van-image">
            <img src="https://qiniu.zhiyunys.com/zejiri/complaint.png" class="van-image__img" />
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";
import banner_0 from "../assets/img/banner_0.png";
import banner_1 from "../assets/img/banner_1.png";
import banner_2 from "../assets/img/banner_2.png";
import banner_3 from "../assets/img/banner_3.png";
import banner_4 from "../assets/img/banner_4.png";
import banner_5 from "../assets/img/banner_5.png";
import banner_6 from "../assets/img/banner_6.png";
import banner_7 from "../assets/img/banner_7.png";
import banner_8 from "../assets/img/banner_8.png";
import banner_9 from "../assets/img/banner_9.png";
import banner_10 from "../assets/img/banner_10.png";
import banner_11 from "../assets/img/banner_11.png";

export default {
  data() {
    return {
      showAnimate: true,
      luckyName: "",
      banner: "",
      banner_0,
      banner_1,
      banner_2,
      banner_3,
      banner_4,
      banner_5,
      banner_6,
      banner_7,
      banner_8,
      banner_9,
      banner_10,
      banner_11,
      price: localStorage.getItem("price"),
      year: new Date().getFullYear(),
      showPay: false,
      hour: "00",
      minute: "00",
      second: "00",
      loading: true,
      percentage: 0,
      show1: true,
      show2: false,
      show3: false,
      show4: false,
      infos: [{}, {}],
      columns: [
        "结婚吉日",
        "订婚吉日",
        "领证吉日",
        "搬家吉日",
        "入宅吉日",
        "提车吉日",
        "开业吉日",
        "装修吉日",
        "动土吉日",
        "嫁娶吉日",
        "动工吉日",
        "出行吉日",
      ],
    };
  },
  methods: {
    getInfo() {
      api
        .getInfo({
          out_trade_no: localStorage.getItem("odd"),
        })
        .then((res) => {
          this.infos = res.userInfo;
          this.luckyName = res.luckyName;
          const index = this.columns.findIndex(
            (item) => item === this.luckyName
          );
          this.banner = this[`banner_${index}`];
        });
    },
    jumpToHelp() {
      this.$router.push("/help");
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        return true; // 移动端
      } else {
        return false; // PC端
      }
    },
    alipay() {
      localStorage.setItem("payway", "ali");
      api
        .alipay1({
          type: this.isMobile() ? "mobile" : "pc",
          odd: localStorage.getItem("odd"),
          // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/main'), // 神马
            Channel: this.$route.query.apkChannel,
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
        })
        .then((res) => {
          let divForm = document.getElementsByTagName("divform");
          if (divForm.length) {
            document.body.removeChild(divForm[0]);
          }
          const div = document.createElement("divform");
          div.innerHTML = res;
          div.style.display = "none";
          document.body.appendChild(div);
          document.getElementById("alipaysubmit").submit();
        });
    },
    wxpay() {
      localStorage.setItem("payway", "wx");
      if (this.isMobile()) {
        api
          .wxpay1({
            type: "mobile",
            odd: localStorage.getItem("odd"),
            // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/main'), // 神马
            Channel: this.$route.query.apkChannel,
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
          })
          .then((res) => {
            location.href = res.code_url;
          });
      } else {
        api
          .wxpay1({
            type: "pc",
            odd: localStorage.getItem("odd"),
            // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // url: `https://${document.domain}/result?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            url: location.href.replace(this.$route.path, '/main'), // 神马
            Channel: this.$route.query.apkChannel,
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&bd_vid=${this.$route.query.bd_vid}`, // 一般
            // domain: `https://${document.domain}/payResult?apkChannel=${this.$route.query.apkChannel}&odd=${this.$route.query.odd}&qhclickid=${this.$route.query.qhclickid}`, // 360
            domain: location.href.replace(this.$route.path, '/payResult'), // 神马
          })
          .then((res) => {
            this.$router.push({
              path: "/wxPay",
              query: {
                info: JSON.stringify(res),
              },
            });
          });
      }
    },
    countDown() {
      // 预计时间
      const _this = this;
      var expectTime =
        +(localStorage.getItem("createTime") || new Date().getTime()) +
        8 * 60 * 60 * 1000;
      var nowTime = new Date().getTime(); //当前时间总的毫秒数
      var inputTime = expectTime; //用户输入时间总的毫秒数
      var times = (inputTime - nowTime) / 1000; //times是剩余时间总的毫秒数
      var timer = setInterval(function () {
        times--;
        // var d = (d = parseInt(times / 60 / 60 / 24))
        // d = d < 10 ? "0" + d : d
        var h = parseInt((times / 60 / 60) % 24);
        h = h < 10 ? "0" + h : h;
        var m = parseInt((times / 60) % 60);
        m = m < 10 ? "0" + m : m;
        var s = parseInt(times % 60);

        if (s > 0) {
          s = s < 10 ? "0" + s : s;
          _this.hour = h;
          _this.minute = m;
          _this.second = s;
        }
        if (s == 0) {
          clearInterval(timer);
        }
        if (s < 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
  mounted() {
    let timer = null;
    let count = 1;
    let num = 4;
    timer = setInterval(() => {
      if (this.percentage === 100) {
        this[`show${++count}`] = true;
        for (let i = 1; i <= num; i++) {
          if (count !== i) {
            this[`show${i}`] = false;
          }
        }
        this.percentage = 0;
        if (count > 4) {
          clearInterval(timer);
          timer = null;
          this.loading = false;
          window.scrollTo(0, 100);
        }
      }
      this.percentage++;
    }, 50);
    // console.log(document.getElementById("info").getElementsByTagName("p"));
    // const pInfo = document.getElementById("info").getElementsByTagName("p");
    // for (let i = 0; i < pInfo.length; i++) {
    //   setTimeout(() => {
    //     pInfo[i].style.display = "block";
    //   }, 1000 * i);
    // }
    this.countDown();
    this.getInfo();
    setTimeout(() => {
      document.getElementById("bazi").className = "active";
      document.getElementById("info").style.display = "block";
      document.getElementById("mingpan-1").style.display = "block";
      Array.from(
        document
          .getElementById("mingpan-1")
          .getElementsByClassName("van-progress__portion")
      ).forEach((item, index) => {
        item.style.width = "100%";
        item.style.transition = `width 4s linear ${index * 4}s`;
      });
    }, 500);
    setTimeout(() => {
      document.getElementById("mingpan-2").style.display = "block";
      document.getElementById("mingpan-1").style.display = "none";
    }, 500 + 8000);
    setTimeout(() => {
      document.getElementById("jiri").className = "active";
      document.getElementById("bazi").className = "";
      Array.from(
        document
          .getElementById("mingpan-2")
          .getElementsByClassName("van-progress__portion")
      ).forEach((item, index) => {
        item.style.width = "100%";
        item.style.transition = `width 5s linear ${index * 5}s`;
      });
    }, 500 + 8500);
    setTimeout(() => {
      document.getElementById("mingpan-3").style.display = "block";
      document.getElementById("mingpan-2").style.display = "none";
    }, 500 + 23500);
    setTimeout(() => {
      document.getElementById("mingli").className = "active";
      document.getElementById("jiri").className = "";
      Array.from(
        document
          .getElementById("mingpan-3")
          .getElementsByClassName("van-progress__portion")
      ).forEach((item, index) => {
        item.style.width = "100%";
        item.style.transition = `width 4s linear ${index * 4}s`;
      });
      this.showAnimate = false;
    }, 500 + 24000);
    setTimeout(() => {
      document.getElementById("info").style.display = "none";
      document.getElementById("bazi1").style.display = "flex";
    }, 4500);
    setTimeout(() => {
      document.getElementById("bazi1").style.display = "none";
      document.getElementById("bazi2").style.display = "flex";
    }, 4500 * 2);
    setTimeout(() => {
      document.getElementById("bazi2").style.display = "none";
      document.getElementById("bazi3").style.display = "flex";
    }, 4500 * 3);
    setTimeout(() => {
      document.getElementById("bazi3").style.display = "none";
      document.getElementById("bazi4").style.display = "flex";
    }, 4500 * 4);
    setTimeout(() => {
      document.getElementById("bazi4").style.display = "none";
      document.getElementById("bazi5").style.display = "flex";
    }, 4500 * 5);
    setTimeout(() => {
      document.getElementById("bazi5").style.display = "none";
      document.getElementById("bazi6").style.display = "block";
    }, 4500 * 6 + 1000);
    setTimeout(() => {
      document.getElementById("bazi7").style.display = "block";
    }, 4500 * 6 + 1000 + 2500);
    setTimeout(() => {
      document.getElementById("bazi6").style.display = "none";
      document.getElementById("bazi7").style.display = "none";
      document.getElementById("bazi8").style.display = "block";
    }, 4500 * 6 + 1000 + 2500);
  },
};
</script>

<style lang="less">
.pay-view {
  position: relative;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #ae3230;
  font-size: 0.23rem;
  .van-image {
    position: relative;
    display: inline-block;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .Lazy-img {
    display: inline-block;
    margin: 0;
    padding: 0;
    .van-image {
      text-align: center;
      width: 100%;
      height: 100%;
      .van-image__img {
        display: inline-block;
      }
    }
  }
  .animate-wrap {
    position: fixed;
    left: 50%;
    top: 0;
    margin-left: -5rem;
    width: 10rem;
    height: 100%;
    padding-top: 5.173333rem;
    box-sizing: border-box;
    z-index: 99;
    .progress-wrap {
      position: relative;
      z-index: 2;
      width: 9.36rem;
      box-sizing: border-box;
      background-image: url("https://qiniu.zhiyunys.com/zejiri/progress-bg.png");
      background-size: 100% 100%;
      background-blend-mode: #f5e5bd;
      margin: 0 auto;
      padding-bottom: 0.88rem;
      .nav {
        display: flex;
        border-bottom: 0.013333rem solid #d7b68e;
        p {
          flex: 1;
          text-align: center;
          font-size: 0.373333rem;
          font-weight: 400;
          color: #333;
          line-height: 1.373333rem;
          &.active {
            position: relative;
            color: #ae3230;
            font-weight: 700;
            span {
              display: block;
              position: absolute;
              left: 50%;
              bottom: 0.266667rem;
              margin-left: -0.48rem;
              width: 0.96rem;
              height: 0.053333rem;
              background: #ae3230;
            }
          }
        }
      }
      .van-progress {
        position: relative;
        height: 4px;
        background: #ebedf0;
        border-radius: 4px;
      }
      .mingpang-wrap {
        padding: 0.52rem;
        font-size: 0.373333rem;
        font-weight: 400;
        color: #333;
        line-height: 0.533333rem;
        display: none;
        .item {
          margin-top: 0.52rem;
        }
      }
      @keyframes process {
        from {
          width: 0 !important;
        }
        to {
          width: 100% !important;
        }
      }
      #mingpan-1 {
        display: block;
      }
    }
    .bg {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      height: 100%;
      margin-left: -5rem;
      z-index: 0;
    }
    .banner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 5.173333rem;
      z-index: 3;
      background-color: transparent;
      .content {
        text-align: center;
        padding-top: 0.76rem;
        box-sizing: border-box;
        .title {
          font-size: 0.6rem;
          font-weight: 700;
          color: #ffe4a7;
          line-height: 0.866667rem;
          margin-bottom: 0.32rem;
        }
        div {
          .mingpang {
            font-size: 0.426667rem;
            font-weight: 500;
            color: #fff;
            line-height: 0.733333rem;
            // margin-bottom: 0.266667rem;
            span {
              color: #ffe4a7;
            }
            p {
              opacity: 0;
              // -webkit-animation-name: fade-in;
              // animation-name: fade-in;
              // -webkit-animation-duration: 0.5s;
              // animation-duration: 0.5s;
              // -webkit-animation-fill-mode: forwards;
              // animation-fill-mode: forwards;
              // &.delay2000 {
              //   animation-delay: 2s;
              // }
              // &.delay1500 {
              //   animation-delay: 1.5s;
              // }
              // &.delay1000 {
              //   animation-delay: 1s;
              // }
              // &.delay500 {
              //   animation-delay: 0.5s;
              // }
            }
          }
          .bazi {
            display: flex;
            padding: 0 0.666667rem;
            div {
              flex: 1;
              font-size: 0.426667rem;
              font-weight: 500;
              color: #fff;
              line-height: 0.733333rem;
              margin-bottom: 0.266667rem;
              span {
                color: #ffe4a7;
              }
              p {
                width: 0.466667rem;
                margin: 0 auto;
                opacity: 0;
                // &.delay1000 {
                //   -webkit-animation-delay: 1s;
                //   animation-delay: 1s;
                // }
                // &.delay2000 {
                //   -webkit-animation-delay: 1s;
                //   animation-delay: 2s;
                // }
                // &.delay5000 {
                //   -webkit-animation-delay: 1s;
                //   animation-delay: 5s;
                // }
                // &.delay7500 {
                //   -webkit-animation-delay: 1s;
                //   animation-delay: 7.5s;
                // }
              }
            }
          }
        }
      }
    }
    @keyframes showEl {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    #info {
      p {
        &:nth-child(1) {
          animation: showEl ease-out 1s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
        }
        &:nth-child(2) {
          animation: showEl ease-out 1s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
        // &:nth-child(3) {
        //   animation: showEl ease-out 1s;
        //   animation-delay: 5s;
        //   animation-fill-mode: forwards;
        // }
        // &:nth-child(4) {
        //   animation: showEl ease-out 1s;
        //   animation-delay: 7s;
        //   animation-fill-mode: forwards;
        // }
      }
    }
    #bazi1 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(3) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(4) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 3.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi2 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(3) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(4) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 3.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi3 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(3) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(4) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 3.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi4 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(3) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(4) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 3.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi5 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(3) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 2.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(4) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 3.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(5) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 4.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi6 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi7 {
      div {
        &:nth-child(1) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 0.5s;
            animation-fill-mode: forwards;
          }
        }
        &:nth-child(2) {
          p {
            animation: showEl ease-out 1s;
            animation-delay: 1.5s;
            animation-fill-mode: forwards;
          }
        }
      }
    }
    #bazi8 {
      p {
        &:nth-child(1) {
          animation: showEl ease-out 1s;
          animation-delay: 0.5s;
          animation-fill-mode: forwards;
        }
        &:nth-child(2) {
          animation: showEl ease-out 1s;
          animation-delay: 2s;
          animation-fill-mode: forwards;
        }
        &:nth-child(3) {
          animation: showEl ease-out 1s;
          animation-delay: 5s;
          animation-fill-mode: forwards;
        }
        &:nth-child(4) {
          animation: showEl ease-out 1s;
          animation-delay: 7s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
  .report-wrap {
    padding: 5.6rem 0.32rem 0 0.32rem;
    position: relative;
    .tit {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 0;
    }
    .des {
      font-size: 0.4rem;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: #ffe7c7;
      line-height: 0.64rem;
      text-align: center;
      span {
        color: #f5bd16;
      }
    }
    .pay-wrap {
      position: relative;
      margin-top: 0.32rem;
      padding: 0.44rem 0 0.6rem 0;
      box-sizing: border-box;
      text-align: center;
      min-height: 8rem;
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 0;
      }
      .title {
        position: relative;
        font-size: 0.533333rem;
        font-family: SourceHanSansSC-Heavy, SourceHanSansSC;
        font-weight: 800;
        color: #ae3230;
        line-height: 0.773333rem;
      }
      .gettime {
        position: relative;
        font-size: 0.4rem;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        color: #333;
        line-height: 0.426667rem;
        margin-top: 0.226667rem;
        margin-bottom: 0.573333rem;
        b {
          font-weight: 700;
        }
      }
      .info-wrap {
        position: relative;
        display: flex;
        .item {
          position: relative;
          flex: 1;
          font-size: 0.32rem;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #333;
          line-height: 0.466667rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          .head {
            width: 1.893333rem;
            height: 1.893333rem;
          }
          .head-wrap {
            display: inline-block;
            position: relative;
            .label {
              position: absolute;
              width: 1.053333rem;
              right: -0.666667rem;
            }
          }
          .name {
            font-size: 0.426667rem;
            font-family: SourceHanSansSC-Bold, SourceHanSansSC;
            font-weight: 700;
            color: #333;
            line-height: 0.613333rem;
            margin: 0.08rem 0;
          }
        }
        .love {
          flex: 0 0 0.533333rem;
          padding-top: 1.053333rem;
        }
      }
      .otherinfo-wrap {
        position: relative;
        margin-top: 0.666667rem;
        text-align: left;
        padding-left: 0.426667rem;
        .wrap {
          margin-bottom: 0.346667rem;
          display: flex;
          p {
            font-size: 0.4rem;
            font-family: SourceHanSansSC-Bold, SourceHanSansSC;
            color: #333;
            line-height: 0.426667rem;
            margin-left: 0.933333rem;
            span {
              font-weight: 700;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .wrapper {
        position: relative;
        z-index: 2;
        text-align: center;
        font-size: 0.373333rem;
        color: #333;
        margin-top: 0.6rem;
        padding: 0 0.32rem;
        .price {
          font-size: 0.4rem;
          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
          font-weight: 400;
          color: #333;
          line-height: 0.586667rem;
          margin-bottom: 0.346667rem;
          span {
            color: #bb312f;
            font-size: 0.453333rem;
          }
          b {
            font-size: 0.64rem;
            font-family: Bebas;
            color: #bb312f;
            line-height: 0.6rem;
          }
          .old {
            font-size: 0.32rem;
            color: #999;
            line-height: 0.466667rem;
            text-decoration: line-through;
            margin-left: 0.2rem;
          }
        }
        .des {
          font-size: 0.32rem;
          line-height: 0.573333rem;
          margin-top: 0.186667rem;
          margin-bottom: 0.586667rem;
          color: #333;
          span {
            width: 0.493333rem;
            height: 0.493333rem;
            line-height: 0.493333rem;
            text-align: center;
            display: inline-block;
            color: #fff;
            background-color: #bb312f;
          }
          b {
            color: #cf1f1f;
            padding: 0 0.066667rem;
          }
        }
        .paybtn {
          display: flex;
          justify-content: center;
          position: relative;
          height: 1.28rem;
          line-height: 1.28rem;
          font-size: 0.48rem;
          font-weight: 700;
          color: #fff;
          margin: 0.4rem auto;
          background: #3871b7;
          border-radius: 0.213333rem;
          &.weixin {
            background: #33a153;
          }
          .identification {
            position: absolute;
            right: 0.906667rem;
            top: -0.4rem;
            width: 1.053333rem;
          }
          .paybtns {
            width: 0.72rem;
            height: 0.72rem;
            margin-right: 0.133333rem;
            margin-top: 0.266667rem;
            float: left;
          }
        }
      }
    }
  }
  .get-list {
    text-align: center;
    padding: 0 0.32rem;
    .title {
      width: 6.88rem;
      margin-top: 0.426667rem;
      margin-bottom: 0.466667rem;
    }
    .item {
      position: relative;
      margin-bottom: 0.546667rem;
      min-height: 6rem;
      .tip {
        position: absolute;
        left: 0;
        top: 1.04rem;
        width: 100%;
        font-size: 0.48rem;
        text-align: center;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        font-weight: 700;
        color: #333;
        line-height: 0.693333rem;
      }
      .comments-wrap {
        position: absolute;
        left: 0;
        bottom: 0.4rem;
        width: 100%;
        height: 7.28rem;
        box-sizing: border-box;
        padding: 0 0.413333rem;
        overflow: hidden;
        @keyframes commentsmove {
          from {
            margin-top: 0;
          }
          to {
            margin-top: -7.28rem;
          }
        }
        .comments {
          animation: commentsmove 10s linear infinite;
          li {
            font-size: 0.373333rem;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #333;
            text-align: left;
            line-height: 1.32rem;
            border-bottom: 0.013333rem solid #aa9982;
            b {
              font-weight: 400;
              color: #ae3230;
            }
            span {
              float: right;
            }
          }
        }
      }
    }
  }
}
</style>