<template>
  <div class="home-view">
    <div class="notice">
      <div class="content" :style="{ margin: `0 ${width}px` }">
        我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用切造成一切后果由其监护人自行承担。本网页数据安全由阿里云提供，严格按照法律法规和用户协议对您的信息进行全方位的保护，请放心使用！
      </div>
    </div>
    <div class="order-tips" v-if="showTips">
      <div class="order-desc">您有进行中的测算订单喔</div>
      <div style="display: flex; align-items: center">
        <div class="go-order" @click="jumpToOrder">立即查看</div>
        <div class="close-tips" @click="showTips = false">关闭</div>
      </div>
    </div>
    <div class="banner">
      <div class="Lazy-img bg">
        <div class="van-image">
          <img src="https://qiniu.zhiyunys.com/zejiri/index_bg.png" class="van-image__img" />
        </div>
      </div>
      <div class="Lazy-img des">
        <div class="van-image">
          <img src="https://qiniu.zhiyunys.com/zejiri/index_head.png" class="van-image__img" />
        </div>
      </div>
      <div class="animate">
        <div
          style="width: 100%; height: 100%; overflow: hidden; margin: 0px auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 563 770"
            width="563"
            height="770"
            preserveAspectRatio="xMidYMid meet"
            style="
              width: 100%;
              height: 100%;
              transform: translate3d(0px, 0px, 0px);
              content-visibility: visible;
            "
          >
            <defs>
              <clipPath id="__lottie_element_2">
                <rect width="563" height="770" x="0" y="0"></rect>
              </clipPath>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_0.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_1.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_2.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_3.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_4.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_5.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_6.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_7.png"></image>
              <image xlink:href="https://qiniu.zhiyunys.com/zejiri/img_8.png"></image>
              <mask id="__lottie_element_21" mask-type="alpha">
                <g
                  transform="matrix(1,0,0,1,205.5,366)"
                  opacity="1"
                  style="display: block"
                >
                  <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                    <path
                      fill="rgb(255,0,0)"
                      fill-opacity="1"
                      d=" M246.5,-47.5 C246.5,-47.5 212,-45 212,-45 C212,-45 178.5,-37 178.5,-37 C178.5,-37 112.5,-13.5 112.5,-13.5 C112.5,-13.5 69,11.5 69,11.5 C69,11.5 34.5,38.5 34.5,38.5 C34.5,38.5 -1,73.5 -1,73.5 C-1,73.5 -23,114 -23,114 C-23,114 -29.5,134.5 -29.5,134.5 C-29.5,134.5 -28.5,167.5 -28.5,167.5 C-28.5,167.5 -17.5,192.5 -17.5,192.5 C-17.5,192.5 0.5,210.5 0.5,210.5 C0.5,210.5 27.5,223.5 27.5,223.5 C27.5,223.5 61,231 61,231 C61,231 112,231.5 112,231.5 C112,231.5 149.5,225 149.5,225 C149.5,225 196.5,210 196.5,210 C196.5,210 241.5,190.5 241.5,190.5 C241.5,190.5 290,158.5 290,158.5 C290,158.5 329,123 329,123 C329,123 351.5,90.5 351.5,90.5 C351.5,90.5 366,52 366,52 C366,52 365,11.5 365,11.5 C365,11.5 345,-16.5 345,-16.5 C345,-16.5 324,-33 324,-33 C324,-33 305.5,-38 305.5,-38 C305.5,-38 246.5,-47.5 246.5,-47.5z"
                    ></path>
                    <path
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      fill-opacity="0"
                      stroke-miterlimit="4"
                      stroke="rgb(196,214,242)"
                      stroke-opacity="1"
                      stroke-width="0"
                      d=" M246.5,-47.5 C246.5,-47.5 212,-45 212,-45 C212,-45 178.5,-37 178.5,-37 C178.5,-37 112.5,-13.5 112.5,-13.5 C112.5,-13.5 69,11.5 69,11.5 C69,11.5 34.5,38.5 34.5,38.5 C34.5,38.5 -1,73.5 -1,73.5 C-1,73.5 -23,114 -23,114 C-23,114 -29.5,134.5 -29.5,134.5 C-29.5,134.5 -28.5,167.5 -28.5,167.5 C-28.5,167.5 -17.5,192.5 -17.5,192.5 C-17.5,192.5 0.5,210.5 0.5,210.5 C0.5,210.5 27.5,223.5 27.5,223.5 C27.5,223.5 61,231 61,231 C61,231 112,231.5 112,231.5 C112,231.5 149.5,225 149.5,225 C149.5,225 196.5,210 196.5,210 C196.5,210 241.5,190.5 241.5,190.5 C241.5,190.5 290,158.5 290,158.5 C290,158.5 329,123 329,123 C329,123 351.5,90.5 351.5,90.5 C351.5,90.5 366,52 366,52 C366,52 365,11.5 365,11.5 C365,11.5 345,-16.5 345,-16.5 C345,-16.5 324,-33 324,-33 C324,-33 305.5,-38 305.5,-38 C305.5,-38 246.5,-47.5 246.5,-47.5z"
                    ></path>
                  </g>
                </g>
              </mask>
            </defs>
            <g clip-path="url(#__lottie_element_2)">
              <g
                class="png"
                transform="matrix(1,0,0,1,97,321)"
                opacity="1"
                style="display: block"
              >
                <image
                  width="493px"
                  height="420px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_8.png"
                ></image>
              </g>
              <g
                class="png"
                transform="matrix(1,0,0,1,290.5,258.5)"
                opacity="1"
                style="display: block"
              >
                <image
                  width="104px"
                  height="232px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_7.png"
                ></image>
              </g>
              <g mask="url(#__lottie_element_21)" style="display: block">
                <g
                  class="png"
                  transform="matrix(-0.3583679497241974,-0.9335803985595703,0.9335803985595703,-0.3583679497241974,373.28448486328125,461.3691711425781)"
                  opacity="1"
                >
                  <image
                    width="160px"
                    height="225px"
                    preserveAspectRatio="xMidYMid slice"
                    xlink:href="https://qiniu.zhiyunys.com/zejiri/img_6.png"
                  ></image>
                </g>
              </g>
              <g class="png" transform="matrix(1,0,0,1,318,52.5)" opacity="1">
                <image
                  width="111px"
                  height="177px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_5.png"
                  id="img_5"
                  style="display: none"
                ></image>
              </g>
              <g class="png" transform="matrix(1,0,0,1,318,52.5)" opacity="1">
                <image
                  width="111px"
                  height="177px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_4.png"
                  id="img_4"
                  style="display: none"
                ></image>
              </g>
              <g
                class="png"
                style="display: block"
                transform="matrix(0.9970836639404297,0,0,0.9970836639404297,316.161865234375,50.758094787597656)"
                opacity="1"
              >
                <image
                  width="111px"
                  height="177px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_3.png"
                  id="img_3"
                ></image>
              </g>
              <g
                class="png"
                style="display: none"
                transform="matrix(0.9960962533950806,0,0,0.9960962533950806,238.21665954589844,34.845481872558594)"
                opacity="1"
              >
                <image
                  width="111px"
                  height="177px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_2.png"
                ></image>
              </g>
              <g
                class="png"
                transform="matrix(1,0,0,1,23.5,347)"
                opacity="1"
                style="display: block"
              >
                <image
                  width="66px"
                  height="66px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_1.png"
                ></image>
              </g>
              <g
                class="png"
                transform="matrix(-0.34191519021987915,0.9397308230400085,-0.9397308230400085,-0.34191519021987915,83.41456604003906,367.44586181640625)"
                opacity="1"
                style="display: block"
              >
                <image
                  width="42px"
                  height="42px"
                  preserveAspectRatio="xMidYMid slice"
                  xlink:href="https://qiniu.zhiyunys.com/zejiri/img_0.png"
                  id="img_0"
                ></image>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="select-wrap">
      <div class="Lazy-img title">
        <div class="van-image">
          <img src="https://qiniu.zhiyunys.com/zejiri/index_time_title.png" class="van-image__img" />
        </div>
      </div>
      <ul class="list">
        <li @click="jumpToAnalysisByType(0)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time1.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(1)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time2.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(2)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time3.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(3)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time4.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(4)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time5.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(5)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time6.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(6)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time7.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(7)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time8.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(8)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time9.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(9)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time10.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(10)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time11.png" class="van-image__img" />
            </div>
          </div>
        </li>
        <li @click="jumpToAnalysisByType(11)">
          <div class="Lazy-img">
            <div class="van-image">
              <img src="https://qiniu.zhiyunys.com/zejiri/index_time12.png" class="van-image__img" />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="introduce-wrap">
      <div class="wrap">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              class="van-image__img"
              data-src="https://qiniu.zhiyunys.com/zejiri/index_detail1.png"
              src="https://qiniu.zhiyunys.com/zejiri/index_detail1.png"
              lazy="loaded"
            />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              class="van-image__img"
              data-src="https://qiniu.zhiyunys.com/zejiri/index_detail2.png"
              src="https://qiniu.zhiyunys.com/zejiri/index_detail2.png"
              lazy="loaded"
            />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              class="van-image__img"
              data-src="https://qiniu.zhiyunys.com/zejiri/index_detail3.png"
              src="https://qiniu.zhiyunys.com/zejiri/index_detail3.png"
              lazy="loaded"
            />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              class="van-image__img"
              data-src="https://qiniu.zhiyunys.com/zejiri/index_detail4.png"
              src="https://qiniu.zhiyunys.com/zejiri/index_detail4.png"
              lazy="loaded"
            />
          </div>
        </div>
      </div>
      <div class="wrap">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              class="van-image__img"
              data-src="https://qiniu.zhiyunys.com/zejiri/index_detail6.png"
              src="https://qiniu.zhiyunys.com/zejiri/index_detail6.png"
              lazy="loaded"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap">
      <p class="tips">平台所有产品拒绝向未成年人提供服务，仅供娱乐和参考</p>
    </div>
    <div class="footer">
      <div class="btn" @click="jumpToAnalysis">
        <img src="https://qiniu.zhiyunys.com/zejiri/more.png" />
        <p>更多测算</p>
      </div>
      <div class="btn analysis-submit" @click="jumpToAnalysis"></div>
      <div class="btn" @click="jumpToOrder">
        <img src="https://qiniu.zhiyunys.com/zejiri/order.png" />
        <p>查询订单</p>
      </div>
    </div>
    <!-- ycc.youqiliang.net -->
    <!-- <p class="company">湖北优起量信息技术有限公司宜昌分公司</p> -->
    <!-- yc.stgamebox.cn -->
    <!-- <p class="company">济南巨量网络科技有限公司经开区分公司</p> -->
    <!-- jr.qm1234.cn -->
    <!-- <p class="company">南京圣亿网络科技有限公司温州第一分公司</p> -->
    <!-- jr.hbkkqlah.cn -->
    <!-- <p class="company">湖北快快起量广告发展有限公司安徽分公司</p> -->
    <!-- yc.hfyuyejy.cn -->
    <!-- <p class="company">合肥钰烨教育咨询有限公司第二分公司</p> -->
    <!-- 360 -->
    <!-- yc.njgkaz.cn -->
    <!-- <p class="company">厦门捷信宇软件科技有限公司</p> -->
    <!-- 神马 -->
    <!-- jr.nndddd.cn -->
    <!-- <p class="company">广西仁斯商务服务有限公司</p> -->
    <!-- jr.zxzst8888.com -->
    <p class="company">江西如石网络科技有限公司</p>
    
  </div>
</template>

<script>
import api from "@/apis/order";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
gsap.registerPlugin(MotionPathPlugin);

export default {
  data() {
    return {
      width: 0,
      top3Height: 130,
      timer: null,
      showTips: false,
    };
  },
  created() {
    setTimeout(() => {
      this.width = document.getElementById("app").clientWidth;
    });
    api.getList().then((res) => {
      if (res.find((item) => item.status === 0)) {
        this.showTips = true;
      }
    });
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    picFloat() {
      setTimeout(() => {
        document.getElementById("img_3").style.animation = `img_3 linear 6s`;
        document.getElementById("img_3").style.display = "block";
      });
      setTimeout(() => {
        document.getElementById("img_3").style.display = "none";
      }, 6000);
      setTimeout(() => {
        document.getElementById("img_4").style.animation = `img_3 linear 6s`;
        document.getElementById("img_4").style.display = "block";
      }, 7000);
      setTimeout(() => {
        document.getElementById("img_4").style.display = "none";
      }, 14000);
      setTimeout(() => {
        document.getElementById("img_5").style.animation = `img_3 linear 6s`;
        document.getElementById("img_5").style.display = "block";
      }, 14000);
      setTimeout(() => {
        document.getElementById("img_5").style.display = "none";
      }, 20000);
    },
    jumpToAnalysis() {
      // this.$router.push("/analysis");
      this.$router.push("/analysis");
    },
    jumpToAnalysisByType(num) {
      this.$router.push({
        path: "/analysis",
        query: {
          type: num || 0,
        },
      });
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
  },
  mounted() {
    const showArea = document.querySelector(".notice");
    showArea.scrollLeft = 0;
    function f() {
      if (
        showArea.scrollLeft >=
        showArea.scrollWidth - document.getElementById("app").clientWidth
      ) {
        showArea.scrollLeft = 0;
      } else {
        const speed = 1;
        showArea.scrollLeft += speed;
      }
      requestAnimationFrame(f);
    }
    requestAnimationFrame(f);
    this.picFloat();
    this.timer = setInterval(() => {
      this.picFloat();
    }, 21000);
  },
};
</script>

<style lang="less">
.home-view {
  min-height: 100vh;
  padding-bottom: 3rem;
  background-color: #a12c2a;
  overflow-y: auto;
  font-size: 0.23rem;
  .notice {
    width: 100%;
    overflow: hidden;
    background: #333;
    padding: 4px 0;
    height: 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    .content {
      opacity: 0.7;
      font-size: 14px;
      color: #fff;
      word-break: keep-all;
      white-space: nowrap;
      position: absolute;
      line-height: 1.5;
    }
  }
  .order-tips {
    display: flex;
    align-items: center;
    height: 0.85rem;
    background-color: #ffd77f;
    padding: 0 0.25rem;
    justify-content: space-between;
    .order-desc {
      display: flex;
      font-size: 0.28rem;
      color: #333;
      align-items: center;
    }
    .go-order {
      width: 1.57rem;
      height: 0.58rem;
      border-radius: 0.32rem;
      text-align: center;
      line-height: 0.58rem;
      font-size: 0.26rem;
      font-weight: 500;
      background-color: #2b2d5d;
      color: #fff;
      margin-right: 0.25rem;
    }
    .close-tips {
      color: #896c35;
      font-size: 0.24rem;
    }
  }
  .van-image {
    position: relative;
    display: inline-block;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .Lazy-img {
    display: inline-block;
    margin: 0;
    padding: 0;
    .van-image {
      text-align: center;
      width: 100%;
      height: 100%;
      display: inline-block;
      .van-image__img {
        display: inline-block;
      }
    }
  }
  .banner {
    position: relative;
    width: 100%;
    height: 12.8rem;
    overflow: hidden;
    .van-image {
      position: relative;
      display: inline-block;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      pointer-events: none;
    }
    .Lazy-img {
      display: inline-block;
      margin: 0;
      padding: 0;
      .van-image {
        text-align: center;
        width: 100%;
        height: 100%;
        display: inline-block;
      }
      &.bg {
        width: 100%;
      }
      &.des {
        position: absolute;
        width: 9.24rem;
        left: 0.386667rem;
        bottom: 1.386667rem;
        z-index: 2;
      }
    }
    .animate {
      position: absolute;
      left: 1.866667rem;
      top: 0;
      width: 85%;
      height: 100%;
      padding: 0.4rem;
      z-index: 0;
      box-sizing: border-box;
      @keyframes img_3 {
        0% {
          display: block;
          transform: scale(1);
        }
        20% {
          display: block;
          transform: scale(1.2);
        }
        40% {
          display: block;
          transform: scale(1);
        }
        60% {
          display: block;
          transform: scale(1.2);
        }
        80% {
          display: block;
          transform: scale(1);
        }
        85% {
          display: none;
        }
        100% {
          display: none;
        }
      }
      @keyframes img_0 {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      #img_0 {
        animation: img_0 linear 2s infinite;
        transform-origin: 21px 21px;
      }
    }
  }
  .select-wrap {
    margin-top: 0.213333rem;
    padding: 0 0.32rem;
    text-align: center;
    background-color: #ae3230;
    .Lazy-img {
      display: inline-block;
      margin: 0;
      padding: 0;
      &.title {
        display: inline-block;
        width: 5.053333rem;
        margin: 0.466667rem auto 0.52rem auto;
      }
    }
    .list {
      li {
        display: inline-block;
        width: 4.533333rem;
        margin-bottom: 0.32rem;
        &:nth-child(odd) {
          margin-right: 0.266667rem;
        }
      }
    }
  }
  .introduce-wrap {
    padding: 0.32rem 0.32rem 0.533333rem 0.32rem;
    text-align: center;
    font-size: 0;
    background-color: #ae3230;
    .wrap {
      margin-bottom: 0.533333rem;
    }
  }
  .footer-wrap {
    text-align: center;
    font-size: 0.4rem;
    font-weight: 500;
    color: #e38f8e;
    line-height: 0.586667rem;
    margin-top: 0.24rem;
    padding: 0 0.28rem;
    .tips {
      font-size: 0.293333rem;
      font-weight: 400;
      color: #e38f8e;
      line-height: 0.426667rem;
      margin-top: 0.093333rem;
    }
    p:last-child {
      margin-top: 0.093333rem;
      font-size: 0.293333rem;
    }
  }
  .footer {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 460px;
    height: 114px;
    background: url("https://qiniu.zhiyunys.com/img/footer-bg.png") no-repeat;
    background-size: 100% 100%;
    z-index: 10;
    .btn {
      position: absolute;
      bottom: 8px;
      text-align: center;
      img {
        width: 38px;
        height: 38px;
      }
      &:first-child {
        left: 0.8rem;
      }
      &:last-child {
        right: 0.8rem;
      }
      p {
        font-size: 14px;
      }
    }
    @keyframes analysis {
      from {
        transform: scale(1.4) translateX(-50%);
      }
      to {
        transform: scale(1.2) translateX(-50%);
      }
    }
    .analysis-submit {
      width: 60px;
      height: 60px;
      background: url("https://qiniu.zhiyunys.com/img/bottom_nav_icon.png")
        no-repeat;
      background-size: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 32px;
      animation: analysis 1.2s linear infinite;
      animation-direction: alternate;
      transform-origin: 0 0;
    }
  }
  .company {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #e38f8e;
  }
}
@media screen and (max-width: 414px) {
  .home-view {
    .footer {
      width: 100%;
      height: 104px;
      .analysis-submit {
        width: 50px;
        height: 50px;
        bottom: 33px;
      }
      .btn {
        &:first-child {
          left: 1rem;
        }
        &:last-child {
          right: 1rem;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .home-view {
    .footer {
      width: 100%;
      height: 104px;
      .analysis-submit {
        width: 43px;
        height: 43px;
        bottom: 41px;
      }
      .btn {
        &:first-child {
          left: 0.8rem;
        }
        &:last-child {
          right: 0.8rem;
        }
      }
    }
  }
}
</style>