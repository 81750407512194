import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
Vue.config.productionTip = false

import less from 'less'
Vue.use(less)

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import Main from '@/pages/Main'
import Analysis from '@/pages/Analysis'
import Order from '@/pages/Order'
import Guide from '@/pages/Guide'
import Help from '@/pages/Help'
import Result from '@/pages/Result'
import WxPay from '@/pages/WxPay'
import Success from '@/pages/Success'
import ResultPayed from '@/pages/ResultPayed'
import Wrapper from '@/pages/Wrapper'
import Protocol from '@/pages/Protocol'
import PayResult from '@/pages/PayResult'

import fastClick from 'fastclick';
fastClick.attach(document.body);
fastClick.prototype.needsFocus = function(target) { //判断当前元素是否需要focus
  switch (target.nodeName.toLowerCase()) {
      case 'textarea':
          return true;
      case 'select':
          return !(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1);
      case 'input':
          switch (target.type) {
              case 'button':
              case 'checkbox':
              case 'file':
              case 'image':
              case 'radio':
              case 'submit':
                  return false;
          }
          // No point in attempting to focus disabled inputs
          return !target.disabled && !target.readOnly;
      default:
          return (/\bneedsfocus\b/).test(target.className);
  }
};
fastClick.prototype.focus = function(targetElement) {
  var length;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
      length = targetElement.value.length;
      targetElement.setSelectionRange(length, length);
      /*修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘*/
      targetElement.focus();
  } else {
      targetElement.focus();
  }
};

function setRootFontSize() {
  var width = document.documentElement.clientWidth || document.body.clientWidth;
  if (width > 460) {
      width = 460;
  }
  const fontSize = (width * 0.1);
  document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px';
}
setRootFontSize();
window.addEventListener('resize', function() {
  setRootFontSize();
}, false);
const router = new VueRouter({
  mode: 'history',
  // base: '/bdaziweidoushu/',
  routes: [
    { path: '/', redirect: '/main'},
    { path: '/main', component: Main },
    { path: '/analysis', component: Analysis },
    { path: '/order', component: Order },
    { path: '/guide', component: Guide },
    { path: '/help', component: Help },
    { path: '/result', component: Result },
    { path: '/wxPay', component: WxPay },
    { path: '/success', component: Success },
    { path: '/resultPayed', component: ResultPayed },
    { path: '/wrapper', component: Wrapper },
    { path: '/protocol', component: Protocol },
    { path: '/payResult', component: PayResult },
  ]
})
router.beforeEach((to, from, next) => {
  let query = { ...to.query };
  if (!to.query.apkChannel) {
    // query.apkChannel = 'BD_zwds01_ty_A04_lh';
    query.apkChannel = from.query.apkChannel || 'vip_note_jiri_01';
  }
  // 一般
  // if (!to.query.bd_vid) {
  //   query.bd_vid = from.query.bd_vid;
  // }
  // 360
  // if (!to.query.qhclickid) {
  //   query.qhclickid = from.query.qhclickid;
  // }
  // 神马
  if (!to.query.sg_vid) {
    query.sg_vid = from.query.sg_vid;
  }
  if (!to.query.isConnect) {
    query.isConnect = from.query.isConnect;
  }
  if (!to.query.uctrackid) {
    query.uctrackid = from.query.uctrackid;
  }
  if (to.query.apkChannel) {
    next();
  } else {
    next({
      path: to.path === '/' ? '' : to.path,
      query,
    });
  }
  window.scrollTo(0, 0);
})
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
